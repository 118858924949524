<template>
  <div>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        <v-card
          :loading="loading"
          :disabled="loading"
          loader-height="2"
          class=""
        >
          <v-card-text class="pb-0">
            <div class="d-flex align-center">
              <v-btn
                rounded
                color="primary"
                elevation="0"
                small
                class="mr-2"
                v-if="$nova.hasRight(right, 'add')"
                @click="addForm()"
                ><v-icon class="mr-1">mdi-plus</v-icon>Add</v-btn
              >
              <lb-string
                label="Search"
                outlined
                hidedetails
                v-model="search"
              ></lb-string>
              <v-spacer></v-spacer>
              <v-chip label small class="border-left-error" outlined
                >Disabled</v-chip
              >
              <v-btn icon small class="ml-2" @click="getData()"
                ><v-icon>mdi-refresh</v-icon></v-btn
              >
            </div>
          </v-card-text>
          <v-spacer></v-spacer>
          <lb-dialog v-model="addRights" :heading="editId ? 'Update' : 'Create'" width="600" :loading="loading">
            <template v-slot:body>
              <v-row class="">
                <v-col cols="12" class="my-0 py-1">
                  <lb-string
                    label="Code"
                    v-model="code"
                    :error="errors['code']"
                  />
                </v-col>
                <v-col cols="12" class="my-0 py-1">
                  <lb-string
                    label="Name"
                    v-model="name"
                    :error="errors['name']"
                  />
                </v-col>
                <v-col cols="12" class="my-0 py-1">
                  <lb-string
                    label="Description"
                    v-model="description"
                    :error="errors['description']"
                  />
                </v-col>
                <v-col cols="12" class="my-0 py-1">
                  <lb-list
                    label="Value"
                    v-model="action"
                    :error="errors['action']"
                    type="stringarray"
                  />
                </v-col>
              </v-row>
            </template>
            <template v-slot:actions>
              <v-spacer></v-spacer>
              <v-btn
                small
                color="primary"
                v-if="editId"
                @click="updateRights()"
                >Update</v-btn
              >
              <v-btn small color="primary" @click="saveRights()" v-else
                >Create</v-btn
              >
            </template>
          </lb-dialog>
          <v-card-text>
            <v-data-table
              :items-per-page="-1"
              :items="gettingData"
              hide-default-footer
              dense
              :headers="headers"
              :search="search"
            >
              <template #[`item.code`]="{ item }">
                <td class="border-left-error" v-if="!item.status">
                  {{ item.code }}
                </td>
                <td class="border-left-transparent" v-else>{{ item.code }}</td>
              </template>
              <template #[`item.action`]="{ item }">
                <span v-for="i of item.action" :key="i">
                  <v-chip label small class="mr-1 mb-1">{{ i }}</v-chip>
                </span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu offset-y transition="slide-y-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="view-on-hover-item-d mx-0 px-0"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense class="pa-0 border-left-default">
                    <v-list-item v-if="$nova.hasRight(right, 'edit')" @click="editItem(item)">
                      <v-list-item-title
                        ><v-icon left>mdi-pencil</v-icon>Edit</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      v-if="item.status && $nova.hasRight(right, 'enable/disable')"
                      @click="disableId(item._id)"
                    >
                      <v-list-item-title
                        ><v-icon color="error" left>mdi-cancel</v-icon
                        >Disable</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item v-else-if="$nova.hasRight(right, 'enable/disable')" @click="enableId(item._id)">
                      <v-list-item-title
                        ><v-icon left color="success">mdi-check-bold </v-icon
                        >Enable</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      right: "permission_right",
      code: "",
      name: "",
      description: "",
      action: [],
      gettingData: [],
      errors: {},
      search: "",
      loading: false,
      addRights: false,
      headers: [
        { text: "Code", value: "code", sortable: false },
        { text: "Name", value: "name", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Value", value: "action", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      editId: null,
    };
  },
  created() {
    this.getData();
  },
  activated() {
    this.getData();
  },
  methods: {
    addForm() {
      this.addRights = true;
      this.code = "";
      this.name = "";
      this.description = "";
      this.action = [];
      this.editId = null;
      this.errors = {};
    },
    saveRights() {
      this.loading = true;
      let data = {
        // code: (this.code || []).map((x) => x.toLowerCase()),
        code: (this.code || "").toLowerCase().replaceAll(" ", ""),
        name: this.name,
        description: this.description,
        action: (this.action || []).map((x) => x.toLowerCase()),
      };
      this.axios
        .post("/v2/rights/add", { data })
        .then((ele) => {
          if (ele.data.status === "success") {
            this.$store.commit("sbSuccess", "Created Sucessfully");
            this.addRights = false;
            this.getData();
          } else {
            if (ele.data.data.length > 0) {
              if (ele.data.data[0].index0)
                this.errors = ele.data.data[0].index0;
            } else throw new Error(ele.data.message);
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateRights() {
      this.loading = true;
      let editData = {
        code: (this.code || "").toLowerCase().replaceAll(" ", ""),
        name: this.name,
        description: this.description,
        action: (this.action || []).map((x) =>
          x.toLowerCase().replaceAll(" ", "")
        ),
      };
      this.axios
        .post("/v2/rights/edit/" + this.editId, { data: editData })
        .then((ele) => {
          if (ele.data.message === "updated successfully") {
            this.$store.commit("sbSuccess", "updated successfully");
            this.addRights = false;
            this.getData();
          } else {
            if (ele.data.data.length > 0) {
              if (ele.data.data[0].index0)
                this.errors = ele.data.data[0].index0;
            } else throw new Error(ele.data.message);
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getData() {
      this.loading = true;
      this.axios
        .post("/v2/rights/get")
        .then((ele) => {
          if (ele.data.status === "success") {
            this.gettingData = ele.data.data;
          } else throw new Error("Error Fetching Data");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    enableId(id) {
      this.loading = true;
      this.axios
        .post("/v2/rights/enable/" + id)
        .then((ele) => {
          if (ele.data.status === "success") {
            this.getData();
          } else throw new Error("Error While Enable");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editItem(item) {
      this.addRights = true;
      this.editId = item._id;
      this.name = item.name;
      this.code = item.code;
      this.description = item.description;
      this.action = item.action;
      this.errors = {};
    },
    disableId(id) {
      this.loading = true;
      this.axios
        .post("/v2/rights/disable/" + id)
        .then((ele) => {
          if (ele.data.status === "success") {
            this.getData();
          } else throw new Error("Error While Disable");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>